import React, { useState, useEffect } from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import { WhitespaceAPI } from '../../helpers/whitespace';
import { utils } from '../../FromElsewhere/utils'
import './CopyFacility.css'
import { Corporate } from '../../Session';
import { CombinedSets } from '../../helpers/CombinedSets';

// https://stackoverflow.com/questions/50774176/sending-file-and-json-in-post-multipart-form-data-request-with-axios
// https://www.pluralsight.com/resources/blog/guides/uploading-files-with-reactjs

interface SourceFacility {
	id : string;
	rev : string;
	umr : string;
	insuredName : string;
	updatedAt : string;
	placingBrokerChannel : string;
}

interface TargetTeam {
	name : string;
	teamId : string;
	channel : string;
	selected : boolean;
}

const MyTeams : TargetTeam[] = [];
const MyProgressMessages: string[] = [];
// let MyCombinedLines : CombinedSets.Root[] = [];
const RLPayloads: any[] = [];

export const CopyFacility = () => {
	const [sourceFacilities, setSourceFacilities] = useState<SourceFacility[]>( [] );
	const [sayuserMessage, sayuser] = useState("Choose the facility to copy");
	const [inceptionDate, setInceptionDate] = useState("");
	const [expiryDate, setExpiryDate] = useState("");
	const [selectedFacility, setSelectedFacility] = useState<SourceFacility | null>( null );
	const [userDetails, setUserDetails] = useState<any>( {} );
	const [teams, setTeams] = useState<TargetTeam[]>( [] );
	const [progressMessages, setProgressMessages] = useState<string[]>( [] );
	const [writtenSets,setWrittenSets] = useState<CombinedSets.Root[]>([]);
	const [signedSets,setSignedSets] = useState<CombinedSets.Root[]>([]);
	const [multiSectionStorer,setMultiSectionStorer] = useState<any>({ sections : [] });
	const [selectedFile, setSelectedFile] = useState<any>();
	const [newIC, setNewIC] = useState( "" );
	const [latestRev, setLatestRev] = useState( "" );

	const [addTeamSuffix, setAddTeamSuffix] = useState( true );
	const [addTimeSuffix, setAddTimeSuffix] = useState( true );
	const [textSuffix, setTextSuffix] = useState( "" );
	const [informationText, setInformationText] = useState( "" );

	const [needsApproval, setNeedsApproval] = useState( "Approval Status not checked");
	const [mandatoryAuthStages, setMandatoryAuthStages] = useState<string[]>([]);
	const [selfApproverTeams, setSelfApproverTeams] = useState<string[]>([]);
	const [originalLineItems, setMRC] = useState<any>();
	const [t1, selt1] = useState();
	
	let targetTeam : TargetTeam | undefined = undefined;
	// let targetIdx = -1;
	// let newid = "";
	// let latestRev = "";

	useEffect(() => {
		WhitespaceAPI.get('/api/user/myDetails').then( response => {
			setUserDetails( response.data );
			const muid = response.data.uniqueID;
			MyTeams.length = 0;
			response.data.teams.forEach( t => {
				const tt : TargetTeam = { name : t.name, channel : t.channel, teamId : t.teamId, selected : false };
				MyTeams.push( tt );
			});
			MyTeams.sort( ( a,b) => { return a.name.localeCompare( b.name ); } );

			let url = `/api/documents/Corporate-${response.data.companyId}`;
			WhitespaceAPI.get( url ).then( response => {
				const corp : Corporate = response.data;
				if( corp.settings?.mandatoryAuthStages?.length ) {
					setNeedsApproval( `${corp.settings?.mandatoryAuthStages?.length} mandatoryAuthStages`);
					setMandatoryAuthStages( corp.settings.mandatoryAuthStages );
					setSelfApproverTeams( Corporate.getTeamsWhereIsSecondApprover( corp, muid ));
				} else {
					setNeedsApproval( "No mandatoryAuthStages" );
				}
				url = "/api/summary";
				const payload = { types : [ "Facilities" ], statuses: [ "Signed" ] };
				WhitespaceAPI.post( url, payload ).then( response => {
					console.log( `${url} returned ${response.data.length} items`);
					const sfarray : SourceFacility[] = [];
					response.data.forEach( itm => { 
						const sf = mayMakeSourceFacility( itm );
						if( sf ) {
							sfarray.push( sf );
						}
						
					});
					console.log( `created ${sfarray.length} sfarray items`);
					setSourceFacilities( sfarray );
				});
			});
		} )
	}, []);

	const mayMakeSourceFacility = ( itm : any ) : SourceFacility | null => {
		let result : SourceFacility | null = null;
		if( !itm.docs ) {
			return result;
		}
		itm.docs.forEach( doc => {
			if( doc.type == "RWPlacing" && doc.isFacility == true && doc.status == "Signed" && doc.id.endsWith( "::SIGNED") ) {
				result = { id : doc.id, rev: doc.rev, umr : doc.umr, insuredName : doc.insuredName, updatedAt : doc.updatedAt, placingBrokerChannel : doc.placingBrokerChannel };
				console.log( result );
			}
		});
		return result;
	}

	const pickSource = ( sf : SourceFacility ) => {
		MyProgressMessages.length = 0;
		setProgressMessages( MyProgressMessages.slice() );
		sayuser( `Selected ${sf.umr}`)
		setSelectedFacility( sf );
		MyTeams.forEach( t => { t.selected = false; });
		setTeams( MyTeams.filter( t => { return t.channel != sf.placingBrokerChannel }) );
		const signedid = sf.id;
		const rootid = signedid.substring( 0, signedid.indexOf( "::SIGNED"));
		const urlFacId = `/api/risks/${rootid}`
		WhitespaceAPI.get(urlFacId).then( response => {
			setMRC(response.data.MRCContract);
		});

		setInformationText( `Stub copy of ${signedid}` );
		let url = `/api/lines/${rootid}/combinedSets`;
		console.log( `url is ${url}`);
		WhitespaceAPI.get( url ).then( response => {
			console.log( `done ${url}`);
			const writtenSets = response.data.filter( s => { return s.type == "RWWrittenLineSet"; });
			prepareRLPayloads( writtenSets );
			setWrittenSets( writtenSets );
			setSignedSets( response.data.filter( s => { return s.type == "RWSignedLineSet"; }) );

			url = `/api/documents/${rootid}::MS`;
			WhitespaceAPI.get( url, { silent : true } ).then( response => {
				console.log( `done ${url}`);
				if( response.data && response.data._id && response.data._id.startsWith( rootid ) ) {
					setMultiSectionStorer( response.data );
				} else {
					setMultiSectionStorer( { sections : [] } );
				}
				url = `/api/v23.09/data/${signedid}`;
				WhitespaceAPI.get( url ).then( response => {
					if( !response.data ) {
						addProgress( `ERROR calling ${url}`);
						return;
					}
					const dpInceptionDate = response.data.definedData.find( dp => dp.tag == "Inception_Date");
					const dpExpiryDate = response.data.definedData.find( dp => dp.tag == "Expiry_Date");
					const thisYear = (new Date()).getFullYear();
					if( !dpInceptionDate || !dpExpiryDate ) {
						sayuser( "WARNING : Inception Date and/or Expiry Date are missing from tagged data in the Facility");
					}
					setInceptionDate( dpInceptionDate ? dpInceptionDate.value : `1 Jan ${thisYear}`);
					setExpiryDate( dpExpiryDate ? dpExpiryDate.value : `31 Dec ${thisYear}`);
				});
			});
		});
	}

	const prepareRLPayloads = ( writtenSets : CombinedSets.Root[] ) => {
		RLPayloads.length = 0;
		writtenSets.forEach( s => { 
			RLPayloads.push( prepareRLPayload( "", s ) );
		});
	}

	const prepareRLPayload = ( rev : string, writtenSet : CombinedSets.Root ) : any => {
		const payload : any = { _rev : rev, items: [] };
		writtenSet.contents.forEach( c => {
			const item : any = { uwrChannel : c.stamperChannel, saveStamps: true, lines: [] };
			
			c.impressions.forEach( imp => {
				const line2 : any = { impressions: [] };
				if( c.sectionIdentifiers.length ) {
					line2.sectionIDs = [ c.sectionIdentifiers[0] ];
					line2.sectionInfo = { index : 1, multiSectionId : c.sectionIdentifiers[0], multiSectionName : "TODO"};
				} else {
					// line.sectionIDs = null;	// TODO - check in non-sectioned case
					line2.sectionIDs = [];	// TODO - check in non-sectioned case
					// line.sectionInfo = {};
				}
				line2.leadUnderwriter = ( c.role == "leader" );
				line2.writtenDate = 1712066273;	// TODO
				line2.facilityRole = c.role;
				line2.conditions = { lineConditions: c.lineConditions, subjectivities: c.subjectivities };

				if(c.subjectivitiesDeadlineNotRequired) {
					line2.conditions.deadlineNotRequired = c.subjectivitiesDeadlineNotRequired;
				}

				let addThisLine = false;
				let line = item.lines.find( x => { return sameLine( x, line2 ); });
				if( !line ) {
					addThisLine = true;
					line = line2;
				}
				
				const imp2 : any = {};
				// imp2.references = imp.uwRefs.map( r => { return { reference : r, riskCodes : "" } } );
				imp2.references = imp.uwRefs.map( (r, index) => { return { reference : r, riskCodes : imp.riskCodes[index].code } } );
				imp2.yearOfAccount = imp.yearOfAccount;
				imp2.writtenPercentage = imp.writtenLinePercentageString;
				imp2.stampID = imp.stamp.uniqueID;
				imp2.bureauMarket = imp.stamp.bureauMarket;
				imp2.businessUnit = imp.stamp.businessUnit;
				imp2.bureauMarketCode = imp.stamp.bureauMarketCode;
				imp2.bureauSubMarket = imp.stamp.bureauSubMarket;
				imp2.stampType = imp.stamp.stampType;
				imp2.shortName = imp.stamp.shortName || "";
				if( imp.brexitData ) {
					imp2.mirrorStampsData = [ makeMirrorStamp( imp.brexitData, "Applying to EEA risks" ) ];
				}
				if( imp.mirrorStamps && imp.mirrorStamps.length ) {
					imp2.mirrorStampsData = [];
					imp.mirrorStamps.forEach( ms => {
						imp2.mirrorStampsData.push( makeMirrorStamp( ms, "" ) );
					});
				}
				line.impressions.push( imp2 );
				if( addThisLine ) {
					item.lines.push( line );
				}
			});
			payload.items.push( item );
		});
		console.log( `Payload for ${writtenSet._id}`);
		console.log( JSON.stringify(payload, null, 4 ) );
		return payload;
	}

	const sameLine = ( line1 : any, line2 : any ) : boolean => {
		if( line1.sectionIDs.length != line2.sectionIDs.length ) {
			return false;
		}
		let i, len;
		for( i = 0, len = line1.sectionIDs.length; i < length; i++ ) {
			if( !line2.sectionIDs.includes( line1.sectionIDs[i] ) ) {
				return false;
			}
		}
		return true;
	}

	const makeMirrorStamp = ( ms_or_bd : any, defaultcustomDescription : string ) => {
		const result : any = {};
		result.references = ms_or_bd.references.slice(); 
		result.businessUnit = ms_or_bd.stamp?.businessUnit || "";	// ": "809sadasdasd",
		result.bureauMarket = ms_or_bd.stamp?.bureauMarket || "";	// ": "24",
		result.bureauMarketCode = ms_or_bd.stamp?.bureauMarketCode || "";	// ": "4566",
		result.bureauSubMarket = ms_or_bd.stamp?.bureauSubMarket || "";	// ": "",
		result.stampID = ms_or_bd.stamp?.uniqueID || "";	// ": "STDF214948-4EAE-4F65-8CF0-9C8DA7D10E57",
		result.stampType = ms_or_bd.stamp?.stampType || "";	// ": "other",
		result.shortName = ms_or_bd.stamp?.shortName || "";	// ": "",
		result.mirrorStampCustomDescription = ms_or_bd.customDescription || defaultcustomDescription;
		return result;
	}

	const teamName = ( channel : string ) => {
		const t = userDetails.teams.find( t => { return t.channel == channel });
		return t ? t.name : channel;
	} 

	const ItemDisplay = ( { sf, idx } ) => {
		return (
			<Row onClick={ () => pickSource( sf ) } className='ClickableRow SmallText' key={idx} >
				<Col className='col-md-2'>{sf.umr}</Col>
				<Col className='col-md-2'>{teamName(sf.placingBrokerChannel)}</Col>
				<Col className='col-md-6'>{sf.insuredName}</Col>
				<Col className='col-md-2'>{utils.formatDateTime(sf.updatedAt,'d MMM YYYY')}</Col>
			</Row>
		)
	}

	const clickTeam = ( t1 ) => {
		const array : TargetTeam[] = [];
		let selected = 0;
		MyTeams.forEach( t => { 
			if( t.channel != selectedFacility?.placingBrokerChannel ) {
				if( t.channel == t1.channel ) {
					t.selected = !t.selected;
				} else {
					t.selected = false;
				}
				if( t.selected ) { selected += 1; }
				array.push( t ); 	
			}
		} );
		setTeams( array );
		selt1(t1.channel)
		saySelectedCount( array );
		if( selected == 1 ) {
			willPassSecondApproval();
		}
	}

	const saySelectedCount = ( array ) => {
		sayuser( `${array.filter( t => { return t.selected }).length} of ${array.length} teams selected`);
	}

	const setAll = ( selected : boolean ) => {
		const array : TargetTeam[] = [];
		MyTeams.forEach( t => { 
			if( t.channel != selectedFacility?.placingBrokerChannel ) {
				t.selected = selected;
				array.push( t ); 
			}
		} );
		setTeams( array );
		saySelectedCount( array );
	}

	// const proceed = () => {
	// 	if( !selectedFacility ) {
	// 		return;
	// 	}
	// 	targetTeam = teams.find( t => { return t.selected; });
	// 	if( !targetTeam == 0 ) {
	// 		sayuser( "Select a teams first");
	// 		return;
	// 	}
	// 	MyProgressMessages.length = 0;
	// 	copyToNextTeam();
	// 	// targetTeams.forEach( t => {
	// 	// 	copyToTeam( rootid, t );
	// 	// });
	// }

	// const copyToNextTeam = () => {
	// 	targetIdx += 1;
	// 	if( targetIdx < 0 || targetIdx >= targetTeams.length ) {
	// 		if( targetTeams.length > 1 ) {
	// 			addProgress( `Completed processing on ${targetTeams.length} team(s)`);
	// 		}
	// 		return;
	// 	}
	// 	let signedid = selectedFacility?.id || "";
	// 	if( signedid.includes( "::SIGNED::") ) {
	// 		signedid = signedid.substring( 0, signedid.indexOf( "::SIGNED::") + 8 );
	// 	}
	// 	const rootid = signedid.substring( 0, signedid.indexOf( "::SIGNED"));
	// 	copyToTeam( rootid, targetTeams[ targetIdx ]);
	// }
	

	// const copyToTeam = ( rootid : string, t : TargetTeam ) => {
	// 	addProgress( `Copying to ${t.name} = ${t.channel}`);
	// 	const insuredName = `${selectedFacility?.insuredName} (${t.name}) ${utils.formatDateTime( new Date(), "HH:mm")}`;
	// 	const information = `Stub copy of ${selectedFacility?.id || ""}`
	// 	const payload = [
	// 		{ "heading" : "UMR", "text" : ( selectedFacility?.umr || "" ) },
	// 		{ "heading" : "INSURED", "text" : insuredName },
	// 		{ "heading" : "INFORMATION", "text" : information },
	// 		{ "heading" : "WRITTEN LINES", "text" : "" }
	// 	];

	// 	const url = `/api/risks/newDraft?teamChannel=${t.channel}`;
	// 	console.log( url );
	// 	WhitespaceAPI.post( url, payload ).then( response => {
	// 		if( !response.data || !response.data.id ) {
	// 			addProgress( `ERROR calling ${url} for ${t.channel}`);
	// 			return;
	// 		}
	// 		const newid = response.data.id;
	// 		if( multiSectionStorer.sections.length > 0 ) {
	// 			multiSectionSave( newid );
	// 		} else {
	// 			draftToFirmOrder( newid );
	// 		}
	// 	});
	// }

	const makeInsuredName = ( t : TargetTeam ) => {
		let insuredName = selectedFacility?.insuredName || "";
		if( addTeamSuffix ) {
			insuredName += ` (${t.name})`;
		}
		if( addTimeSuffix ) {
			insuredName += ` ${utils.formatDateTime( new Date(), "HH:mm")}`;
		}
		if( textSuffix.trim() ) {
			insuredName += ` ${textSuffix.trim()}`;
		}
		if(insuredName.length >= 75) {
			return insuredName.slice(0,75);
		}
		return insuredName;	
	}
	const copyToTeam = ( rootid : string, t : TargetTeam ) => {
		addProgress( `Copying to ${t.name} = ${t.channel}`);

		const insuredName = makeInsuredName( t );
		// const information = `Stub copy of ${selectedFacility?.id || ""}`
		const information = informationText;
	

		const params : any = {
			UMR: selectedFacility?.umr || "",
			contractType: "facility",
			insuranceType: "Insurance",
			insuredName: insuredName,
			placementType: "FullContract",
			teamChannel: t1,
			mode: ""
		};

		const lineItems = originalLineItems.lineItems;

		const newLineItem = {
			elements: [{
				text: "",
				"index": 0
			}],
			fragments: [],
			index: 0,
			mrcHeading: "",
			mrcSection: "",
			nameVariation: "",
			originalHeading: "",
			originalSection: "",
			placeholders: [],
			subItems: []
		}

		const informationLineItem = newLineItem;
		const elements: any = [
			{
				text:information,
				"index":0
			}
		]

		informationLineItem.index = lineItems.length;
		informationLineItem.mrcHeading = "CustomMRCHeading";
		informationLineItem.mrcSection = "RiskDetails";
		informationLineItem.nameVariation = "CustomMRCHeading";
		informationLineItem.originalHeading = "FACILITY CLONE INFO";
		informationLineItem.originalSection = "";
		informationLineItem.elements = elements;

		let lastIndex = -1;

		for(let i=0; i<lineItems.length; i++) {
			if(lineItems[i].mrcSection === informationLineItem.mrcSection) {
				lastIndex =i;
			}
		}

		if(lastIndex === -1) {
			lineItems.push(informationLineItem)
		} else {
			informationLineItem.index = lastIndex + 1;
			lineItems.splice(lastIndex + 1, 0, informationLineItem)
		}

		const newLineItems = originalLineItems;
		newLineItems.lineItems = lineItems;
		const payload: any = {MRCContract : newLineItems};
		const insuredNameForQueryString = encodeURIComponent(insuredName);
		const urlParams = `teamChannel=${t1}&mode=facility&UMR=${selectedFacility?.umr}&contractType=facility&insuranceType=Insurance&insuredName=${insuredNameForQueryString}`
		const urlString = "/api/risks/save?" + urlParams;

		WhitespaceAPI.post(urlString, {MRCContract: payload.MRCContract}).then(response => {
			if( !response.data  ) {
				addProgress( `ERROR calling ${urlString}` );
				return;
			}
			const timeout = 5000;
			setTimeout( function() {
				callSummaryToGetRev( t, insuredName, information, 1 );				
			}, timeout );
		})
	}

	const callSummaryToGetRev = ( t : TargetTeam, insuredName : string, information : string, pass : number ) => {
		const url = `/api/summary`;
		const filter_name = "name:" + insuredName.toLocaleLowerCase();
		const payload = { nameSearch: insuredName };
		const timeout = 5000;
		addProgress( `Calling ${url} and seeking ${filter_name} pass ${pass}`);
		WhitespaceAPI.post( url, payload ).then( response => {
			if( !response.data || !response.data.length ) {
				addProgress( `ERROR calling ${url}` );
				return;
			}
			const candidates : any[] = [];
			response.data.forEach( s => {
				const filter_count = s.filters.filter( f => { return f.startsWith( filter_name ); }).length;
				if( s.channels.length == 1 && s.channels[0] == t.channel && filter_count == 1 ) {
					const candidate = { rootID : s.rootID, updatedAt : s.updatedAt, rev : "" };
					s.docs.forEach( d => {
						if( d.id == s.rootID && candidate.rev == "" ) {
							candidate.rev = d.rev;
						}
					});
					candidates.push( candidate );
				} 
			});
			if( !candidates.length ) {
				addProgress( `ERROR ${insuredName} not found when calling ${url}` );
				return;
			}
			candidates.sort( ( a, b ) => { return b.updatedAt.localeCompare( a.updatedAt ) } );
			if( candidates.length > 1 ) {
				const str = candidates.map( c => c.updatedAt ).join( " > ");
				console.log( `Sorted ${candidates.length} candidates dated ${str}`);
			}
			const newid = candidates[0].rootID;
			const rev = candidates[0].rev;
			addProgress( `New risk is ${newid} rev ${rev}`);
			if( rev ) {
				havingGotNewIdAndRev( newid, rev, insuredName, information );
			} else if( pass < 5 ) {
				addProgress( `No revision found from ${url} ... will call again ... pausing for ${timeout}ms`);
				callSummaryToGetRev( t, insuredName, information, pass + 1 );
			} else {
				addProgress( `ERROR ${pass} calls with ${timeout}ms pauses to ${url} still failed`);
			}
		});	
	}

	const havingGotNewIdAndRev = ( newid : string, rev : string, insuredName : string, information : string ) => {
		addProgress( `Modifying ${newid} rev ${rev}` );
		const timeout = 5000;

		setTimeout( function() {
			if( multiSectionStorer.sections.length > 0 ) {
				multiSectionSave( newid );
			} else {
				checkForSecondApproval( newid );
			}			
		}, timeout );
	}

	const multiSectionSave = ( newid : string ) => {
		const payload = multiSectionStorer.sections.map( s => { 
			return { index: s.index, multiSectionId : s.multiSectionId, multiSectionName: s.multiSectionName };
		});
		const url = `/api/sections/${newid}/save`;
		WhitespaceAPI.post( url, payload ).then( response => { 
			if( !response.data ) {
				addProgress( `ERROR calling ${url}` ); 
			} else {
				addProgress( `${response.data.id} created by ${url}`);
				checkForSecondApproval( newid );
			}
		});
	}

	const willPassSecondApproval = () : boolean => {
		if( !setTargetTeam() ) {
			sayuser( "Select a team first");
			return false;
		}
		if( mandatoryAuthStages.length == 0 ) {
			return true;
		}
		if( !selfApproverTeams.includes( targetTeam?.teamId || "??" ) ) {
			sayuser( `${targetTeam?.name || "??"} not in the ${selfApproverTeams.length} team(s) where you are a self approver ${selfApproverTeams.join(", ")}`);
			return false;
		}
		return true;
	}

	const checkForSecondApproval = ( newid : string ) => {
		if( !setTargetTeam() ) {
			sayuser( "Select a team first");
			return;
		}
		if( mandatoryAuthStages.length == 0 ) {
			draftToFirmOrder( newid );
			return;
		}
		if( !willPassSecondApproval() ) {
			return;
		}
		const url = `/api/risks/${newid}/selfApproveSecondApproval`;
		WhitespaceAPI.post( url, {} ).then( response => {
			if( !response.data || !response.data.msg ) {
				addProgress( `ERROR calling ${url}` );
				if( response.data ) {
					console.log( `ERROR calling ${url}` );
					console.log( response.data )
				}
				return;
			}
			addProgress( `${response.data.msg} from ${url}` );
			draftToFirmOrder( newid );
		});
	}

	const draftToFirmOrder = ( newid : string ) => {
		let url = `/api/risks/${newid}/draftToFirmOrder`;
		WhitespaceAPI.post( url, {} ).then( response => {
			if( !response.data || !response.data.msg ) {
				addProgress( `ERROR calling ${url}` );
				return;
			}
			addProgress( `${response.data.msg} from ${url}` );
			url = `/api/risks/${newid}::FO`;
			WhitespaceAPI.get( url ).then( response => {
				if( !response.data || !response.data._id || !response.data._rev ) {
					addProgress( `ERROR calling ${url}` );
					return;
				}
				setNewIC( response.data._id );
				setLatestRev( response.data._rev );
				addProgress( `Firm Order fetched ok`);
				sayuser( "Facility Firm Order prepared. Click 'Copy Lines' next");
				// callRecordLine( id, rev, 0 );
			});
		});
	}

	const callRecordLine = ( id : string, rev: string, idx : number ) => {
		if( idx < 0 || idx >= writtenSets.length ) {
			sayuser( `Completed recording lines for ${targetTeam?.name || "??"}`);
			addProgress( `Completed recording lines for ${targetTeam?.name || "??"}`);
			setNewIC( "" );
			setLatestRev( "" );
			setAll( false );
			return;
		}
		const timeout = 2000;
		const wrls = writtenSets[ idx ];
		const description = describeWRLS( wrls );
		const payload = prepareRLPayload( rev, wrls );
		let url = `/api/risks/${id}/recordLine`;
		WhitespaceAPI.post( url, payload ).then( response => {
			if( !response.data || !response.data.msg ) {
				addProgress( `ERROR calling ${url} ${description}` );
				return;
			}	
			addProgress( `${url} returned ${response.data.msg} ${description} ... pausing for ${timeout} ms`);

			setTimeout( function() {
				url = `/api/risks/${id}`;
				WhitespaceAPI.get( url ).then( response => {
					rev = response.data._rev;
					// addProgress( `${id} revision ${rev}`);
					callRecordLine( id, rev, idx + 1 );
				});
			}, timeout );
		});
	}

	const describeWRLS = ( wrls : CombinedSets.Root ) : string => {
		return `${wrls.contents[0].stamperChannel} ${wrls.contents[0].sectionIdentifiers.join(",")}`;
	}

	const addProgress = ( str : string ) => {
		MyProgressMessages.push( str );
		console.log( `${MyProgressMessages.length} messages after adding ${str}`);
		setProgressMessages( MyProgressMessages.slice() );
	}



	const LineSetDisplay = ( { s } ) => {
		const mySet : CombinedSets.Root = s;
		return (
			<div>
				<div>{mySet._id}</div>
				{mySet.contents.map( ( c, idx ) => <SetContentDisplay c={c} key={idx} /> )}
			</div>
		)
	}
	const SetContentDisplay = ( { c } ) => {
		const x : CombinedSets.Content = c;
		let sectdesc = "No Sections";
		if( x.sectionIdentifiers.length == 1 ) { sectdesc = "Section " + x.sectionIdentifiers.join( ", ") }
		if( x.sectionIdentifiers.length > 1 ) { sectdesc = "Sections " + x.sectionIdentifiers.join( ", ") }
		return (
			<>
			<div>
				<div className='Indented1'>{x.role} : {x.businessUnit} <span className='LightGray'>{sectdesc}</span></div>
				{x.impressions.map( ( imp, idx ) => <ImpressionDisplay imp={imp} key={idx} /> )}
			</div>
			</>
		)
	}
	const ImpressionDisplay = ( { imp } ) => {
		const x : CombinedSets.Impression = imp;
		return (
			<>
			<div>
				<div className='Indented2 SmallText'>{x.writtenLinePercentageString}% {x.stamp.bureauMarket} {x.stamp.bureauMarketCode} {x.stamp.bureauSubMarket} {x.uwRefs.join( ", ") }</div>
			</div>
			</>
		)
	}

	const ProgressMessageDisplay = ( { msg } ) => {
		const cname = msg.startsWith( "ERROR" ) ? "WarningText" : "LightGray";
		return (
			<div className={cname}>{msg}</div>
		)
	}

	const changeFile = ( event ) => {
		setSelectedFile(event.target.files[0]);
	}

	const makeStub = () => {
		if( !willPassSecondApproval() ) {
			return;
		}
		if( selectedFacility ) {
			let signedid = selectedFacility?.id || "";
			if( signedid.includes( "::SIGNED::") ) {
				signedid = signedid.substring( 0, signedid.indexOf( "::SIGNED::") + 8 );
			}
			const rootid = signedid.substring( 0, signedid.indexOf( "::SIGNED"));
			copyToTeam( rootid, targetTeam! );
		}
	}

	/*
	[
		{
			"id": "IC83D73E12-6E13-48F6-B4BF-45F03A9A73E7",
			"team": "SPACE",
			"name": "at1700.wsdraft"
		}
	]
	*/
	const importJSON = () => {
		sayuser( "importJSON NYI");
		if( !willPassSecondApproval() ) {
			return;
		}
		if( !selectedFile ) {
			sayuser( "Choose the ws draft export first");
			return;
		}
		const settings = { draftTeamChannels :[targetTeam!.channel], templateTeamChannels:[]}
		sayuser( `Starting import of ${selectedFile.name}` );
		let url = "/api/import/commit";
		const formData = new FormData();
		formData.append(selectedFile.name, selectedFile );
		formData.append("settings", JSON.stringify(settings));
		const insuredName = makeInsuredName( targetTeam! );

		WhitespaceAPI.post( url, formData ).then( response => {
			if( !response.data || !response.data[0] || !response.data[0].id ) {
				addProgress( `ERROR ${url} failed ${JSON.stringify(response.data)}`)
				return;
			}
			const newid = response.data[0].id;
			addProgress( `New risk is ${newid} created by ${url}`);
			setNewIC( newid );

			url = `/api/risks/changeRiskName/${newid}`;
			const payload = { newName : insuredName }; 
			WhitespaceAPI.post( url, payload ).then( response => {
				if( !response.data ) {
					addProgress( `ERROR ${url} failed`)
					return;
				}
				addProgress( `Set name to ${insuredName} using ${url}`);
				if( multiSectionStorer.sections.length > 0 ) {
					multiSectionSave( newid );
				} else {
					checkForSecondApproval( newid );
				}
			});
		});
	}

	const setTargetTeam = () : TargetTeam | undefined => {
		targetTeam = teams.find( t => { return t.selected; });
		return targetTeam;
	}
	const copyLines = () => {
		if( !setTargetTeam() ) {
			sayuser( "Select a team first");
			return;
		}
		if( !newIC || !latestRev ) {
			sayuser( `newIC :${newIC}: / latestRev :${latestRev}: not set`);
			return;
		}
		MyProgressMessages.length = 0;
		sayuser( "Starting Copy Lines process");
		addProgress( "Starting Copy Lines process");
		callRecordLine( newIC, latestRev, 0 );
	}

	const backToPicker = () => {
		setSelectedFacility(null);
		setSelectedFile(null);
		setNewIC( "" );
	}

	// UI //////////////////////////////////////////////////////////////////////////////////////////////////////////

	const TeamDisplay = ( { t } ) => {
		const className = `ClickableRow Above2 ${t.selected ? "TeamSelected" : ""}`;
		let approvalWarning = "";
		if( mandatoryAuthStages.length > 0 && !selfApproverTeams.includes( t.teamId ) ) {
			approvalWarning = "DISALLOWED - you are not a self-approver";
		}
		return (
			<div className={className} onClick={() => clickTeam( t )}>
				- {t.name} ({t.teamId}) {t.selected ? " - Selected" : ""} {approvalWarning}
			</div>
		)
	}

	return (
		<>
		<div className='WarningBanner'>{sayuserMessage}&nbsp;</div>
		<div>
			Approval : {needsApproval} : Self Approver on {selfApproverTeams.length ? "" : "No Teams"}{selfApproverTeams.join( ", ")}
		</div>
		{!selectedFacility && 
			<>
			<div>{sourceFacilities.length} facilities to choose from</div>
			{sourceFacilities.map( ( sf, idx ) => <ItemDisplay sf={sf} key={idx} idx={idx}/> ) }
			</>
		}
		{ selectedFacility && 
			<>
			<div>Selected {selectedFacility.umr} / {selectedFacility.insuredName} / {teamName(selectedFacility.placingBrokerChannel)} / {inceptionDate} to {expiryDate}</div>
			{
				selectedFile && 
				<div>File {selectedFile?.name || "-"} has {selectedFile?.size || "-"} bytes, last modified {selectedFile?.lastModifiedDate?.toLocaleDateString() || "-" }</div>
			}
			<div className='Above12'>
			<h3>Choose the exported draft, if you do not want to create a stub</h3>
				{selectedFile ? (
					<div>
						File {selectedFile?.name || "-"} has {selectedFile?.size || "-"} bytes, last modified {selectedFile?.lastModifiedDate?.toLocaleDateString() || "-" }
					</div>
				) : (
					<p>Select a file to show details</p>
				)}
				<div>
					WS Draft File to Import <input type="file" name="file" onChange={changeFile} />
				</div>
			</div>
			<div className='Above12'>
				<h3>Naming Options</h3>
					<div>
						<label className='Right30'>
							<input id='chkTeamSuffix' type='checkbox' checked={addTeamSuffix} onClick={()=>setAddTeamSuffix(!addTeamSuffix)}></input>
							&nbsp; Add Team Name
						</label>
						<label className='Right30'>
							<input id='chkTimeSuffix' type='checkbox' checked={addTimeSuffix} onClick={()=>setAddTimeSuffix(!addTimeSuffix)}></input>
							&nbsp; Add Time (Testing Only)
						</label>
						<span>Text Suffix</span> <input type='textbox' value={textSuffix} onChange={(e) => {setTextSuffix(e.target.value);} }></input>
					</div>
					<div>Text for Information heading (if using the stub option)</div>
					<div>
						<textarea className='InformationTextArea' onChange={(e) => {setInformationText(e.target.value);} }>{informationText}</textarea>
					</div>
			</div>
			<div className='Above12'><h3>Choose the team to copy to</h3></div>
			<div>
			{teams.map( ( t, idx ) => <TeamDisplay t={t} key={idx}/> )}
			</div>
			<div className='Above12'>
				<Button onClick={backToPicker}>Back</Button>
				{/* &nbsp;<Button onClick={() => setAll(true)}>Select All</Button> */}
				{/* &nbsp;<Button onClick={() => setAll(false)}>Clear All</Button> */}
				{/* &nbsp;<Button onClick={proceed} disabled={!teams.filter(t => { return t.selected}).length }>Proceed</Button> */}
				&nbsp;<Button onClick={makeStub} disabled={!teams.filter(t => { return t.selected}).length }>Make Stub</Button>
				&nbsp;<Button onClick={importJSON} disabled={!teams.filter(t => { return t.selected}).length || !selectedFile }>Import JSON</Button>
				&nbsp;<Button onClick={copyLines} disabled={!teams.filter(t => { return t.selected}).length || !newIC}>Copy Lines</Button>
			</div>
			<div className='Above12 SmallText'>
				{progressMessages.map( ( msg, idx ) => <ProgressMessageDisplay key={idx} msg={msg} /> )}
			</div>
			{writtenSets.length > 0 &&
				<div className='Above12'>
					<div>{writtenSets.length} lines to be copied</div>
					{writtenSets.map( ( s, idx ) => <LineSetDisplay s={s} key={idx} /> )}
				</div>
			}
			</>
		}
		</>
	)
 }